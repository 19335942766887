import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
const Navbar = lazy(() => import('./components/Navbar'));
const Footer = lazy(() => import('./components/Footer'));
const Discalimer = lazy(() => import('./components/Disclaimer'));
const TermsAndConditions = lazy(() => import('./components/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const PressReleases = lazy(() => import('./about/PressRelease'));
const TeachersPage = lazy(() => import('./components/TeachersPage'));
const NotFound = lazy(() => import('./components/NotFound'));
const CourseDetail = lazy(() => import('./courses/CourseDetail'));
const PaymentPage = lazy(() => import('./pricing/PaymentPage'));
const LearnMorePage = lazy(() => import('./components/LearnMore'));
const BlogPage = lazy(() => import('./resources/BlogPage'));
const BlogPost = lazy(() => import('./resources/BlogPost'));
const LiveActivity = lazy(() => import('./components/LiveActivity'));
const ImagePopup = lazy(() => import('./components/ImagePopup'));
const CookieConsent = lazy(() => import('./components/CookieConsent'));

const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./about/page'));
const Contact = lazy(() => import('./contact/page'));
const Courses = lazy(() => import('./courses/page'));
const Pricing = lazy(() => import('./pricing/page'));
const Resources = lazy(() => import('./resources/page'));

function App() {
  return (
    <>
      <HelmetProvider>
        <Router>
          <Helmet>
            <meta name="author" content="Gourav" />
            <link rel="icon" href="/favicon.ico" />
            <title>Oracy</title>

            {/* Preload key CSS */}
            <link rel="preload" href="/index.css" as="style" />

            {/* Load non-critical CSS asynchronously */}
            <link rel="stylesheet" href="/index.css" media="print" onLoad="this.media='all'" />
            <noscript>{`<link rel="stylesheet" href="/index.css" />`}</noscript>

            {/* Preconnect to Google Fonts */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

            {/* Load fonts with font-display swap */}
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap" rel="stylesheet" />
          </Helmet>
          <Suspense fallback={<div>Loading...</div>}>
            <ImagePopup />
            <CookieConsent />
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/:level" element={<CourseDetail />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/blogs/latest" exact element={<BlogPage/>} />
              <Route path="/blog/:id" element={<BlogPost/>} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/termsandconditions" element={<TermsAndConditions/>} />
              <Route path="/disclaimer" element={<Discalimer/>} />
              <Route path="/about/press-releases" element={<PressReleases />} />
              <Route path="/teachers" element={<TeachersPage/>} />
              <Route path="/learnmore" element = {<LearnMorePage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <LiveActivity/>
            <Footer />
          </Suspense>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
